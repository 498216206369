@import '~bootstrap/dist/css/bootstrap.css';

body {
  background-color: #F8F8F8;
  margin: 0;
  padding: 0 0 30px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input:focus,
input:active,
button:focus,
button:active {
  box-shadow: none !important;
}

label {
  font-weight: bold;
}
